import React, { useState, useEffect } from "react";
import axios from "axios";
import { has } from "lodash";
import qs from "qs";
import toppic from "./assets/img/header.jpg";
import banner from "./assets/img/banner.jpg";
import { isBrowser, isMobile } from "react-device-detect";

function App() {
  const questions = [
    {
      q: "Vem av följande har gjort flest SHL-matcher?",
      opt: [
        "Joel Lundqvist, Frölunda",
        "Patrik Zackrisson, Leksand",
        "Joakim Lindström, Skellefteå",
      ],
    },
    {
      q: "Elitserien blev SHL 2013. Vem blev första lagkapten att lyfta bucklan som SHL-mästare våren 2014?",
      opt: [
        "Rickard Wallin, Färjestad",
        "Jimmie Ericsson, Skellefteå",
        "Johan Markusson, Växjö",
      ],
    },
    {
      q: "Vilken match vill du ha biljetter till?",
      opt: [
        {
          date: "Lördag 7 december",
          games: [
            "Modo-Brynäs",
            "Timrå-Luleå",
            "Örebro-Malmö",
            "Färjestad-Skellefteå",
            "Leksand-Växjö",
            "Linköping-Frölunda",
            "Rögle-HV71",
          ],
        },
        {
          date: "Torsdag 19 december",
          games: [
            "Luleå-Modo",
            "Skellefteå-Timrå",
            "Frölunda-HV71",
            "Linköping-Rögle",
            "Växjö-Örebro",
            "Brynäs-Färjestad",
            "Malmö-Leksand",
          ],
        },
         {
          date: "Måndag 30 december",
          games: [
            "HV71-Skellefteå",
          ],
        },
      ],
    },
  ];

  const [answers, setAnswers] = useState(
    new Array(questions.length).fill(false)
  );
  const [motivation, setMotivation] = useState("");
  const [isQuesDone, setIsQuesDone] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [check, setCheck] = useState(false);
  const [showThx, setShowThx] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [allDone, setAllDone] = useState(false);

  useEffect(() => {
    let done = true;
    let aDone = true;

    answers.forEach((item, index) => {
      if (!item && index < 2) {
        done = false;
      }
      if (!item) {
        aDone = false;
      }
    });
    if (!done) {
      setMotivation("");
    }

    setIsQuesDone(done);
    setAllDone(aDone);
  }, [answers]);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const sendData = () => {
    setLoading(true);
    let data = {
      data: {
        name: name,
        phone: phone,
        email: email,
        ques1: answers[0],
        ques2: answers[1],
        want: answers[2],
        motiv: motivation,
      },
    };

    let h = "cuppen.se";
    let host_endpoint = "/api/";
    let endpoint = "save";
    let host = window.location.hostname;
    if (host === "localhost") {
      host = h;
    }
    host = window.location.protocol + "//" + host + host_endpoint;

    return axios
      .post(host + endpoint, qs.stringify(data, { parseArrays: false }))
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setShowThx(true);
          setAnswers(new Array(questions.length).fill(false));
          setName("");
          setPhone("");
          setEmail("");
          setMotivation("");
          setCheck(false);
          setAllDone(false);
          setIsQuesDone(false);
          setTimeout(() => {
            setShowThx(false);
          }, 5000);
        }
      })
      .catch((err) => {
        setLoading(false);

        return Promise.reject(err.response);
      });
  };
  return (
    <div className={isMobile ? "App mobile" : "App"}>
      <div className="cornerHolder">
        <div className={"corner"} />
      </div>
      <div className="toppic"></div>
      <div className="block n1">
        <div className="info">
          <h1 style={{ marginBottom: 15 }}>
            Vill du se SHL-hockey live och hjälpa din klubb att få 50 000 kr?
          </h1>
          <p>
            Det här är tävlingen där du kan vinna fyra matchbiljetter, samt
            hjälpa din klubb att få 50 000 kronor. Den som vet mest, och skriver
            bäst motivering, vinner mest. Lycka till!
          </p>
          {/*<span>
            Oddset har haft en tävling där priset varit biljetter till herr- och
            damfinalen i Svenska Cupen.
          </span>*/}
          {/*<br /><span className="header">Vinnarna till herrfinalen:</span>

          <p>
            1:a pris: Peter Nässén.
            <br />
            2:a pris: Stefan Winberg, Emil Lindma, David Alkemark, Jonatan
            Lundén.
          </p>

          <span className="header">Vinnarna till damfinalen:</span>
          <p>
            1:a pris: Helena Lorentzon.
            <br />
            2:a pris: Johanna Wiesel, Michael Olausson, Ingela Wallin, Daniel
            von Zweigbergk.
          </p>
*/}
          <span className="header">Tävlingsupplägget</span>

          <ul style={{ marginLeft: 20, marginBottom: 40 }}>
            <li>Svara på frågorna.</li>
            <br />
            <li>
              Skriv en motivering där du beskriver vad som är det bästa med ditt
              SHL-lag. Det vassaste bidraget, som även har svarat rätt, vinner
              fyra biljetter till en hemmamatch antingen 7 eller 19 december.
            </li>
            <br />
            <li>
              Det SHL-lag som får{" "}
              <span style={{ textDecoration: "underline" }}>flest antal</span>{" "}
              motiveringar, med rätta svar, erhåller 50 000 kronor.
            </li>
          </ul>
          <a
            className="yellow"
            style={{
              color: "white",
              marginBottom: 40,
              textDecoration: "underline",
              fontWeight: "bold",
            }}
            href="./files/regler_och_villkor_hockeykampen.pdf"
            target="_blank"
          >
            Fullständiga regler och villkor hittar du här
          </a>
          <span className="header">Frågor</span>
          <div className="competitionHolder">
            <div className="competition">
              {questions.map((item, index) => {
                if (index === 2) {
                  return;
                }
                return (
                  <div key={"q" + index} className="question">
                    <span className="header">{item.q}</span>
                    {item.opt.map((q, ind) => {
                      let start = "1: ";
                      if (ind === 1) {
                        start = "X: ";
                      } else if (ind === 2) {
                        start = "2: ";
                      }
                      return (
                        <p
                          key={"qa" + index + "_" + ind}
                          className={
                            answers[index] !== false &&
                            answers[index] === start + q
                              ? "selected"
                              : ""
                          }
                          onClick={() => {
                            let a = [...answers];
                            a[index] = start + q;
                            setAnswers(a);
                          }}
                        >
                          {start + q}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
              <div
                className="mot"
                style={
                  !isQuesDone
                    ? { opacity: 0.5, marginBottom: 70 }
                    : { marginBottom: 70 }
                }
              >
                <span className="header">
                  Vad är det bästa med ditt SHL-lag? (Max 500 tecken)
                </span>

                <textarea
                  disabled={!isQuesDone}
                  onChange={(val) => {
                    if (val.target.value.length <= 500) {
                      setMotivation(val.target.value);
                    }
                  }}
                  value={motivation}
                />
                <span className="counter">( {motivation.length} )</span>
                {questions.map((item, index) => {
                  if (index < 2) {
                    return;
                  }
                  return (
                    <div key={"q" + index} className="question">
                      <span className="header">{item.q}</span>
                      {item.opt.map((g, ind) => {
                        let games = g.games.map((q, i) => {
                          return (
                            <p
                              key={"qa" + index + "_" + ind + "_" + i}
                              className={
                                answers[index] !== false && answers[index] === q
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => {
                                if (isQuesDone) {
                                  let a = [...answers];
                                  a[index] = q;
                                  setAnswers(a);
                                }
                              }}
                            >
                              {q}
                            </p>
                          );
                        });
                        return (
                          <>
                            <b
                              style={{
                                color: "white",
                                textDecoration: "underline",
                              }}
                            >
                              {g.date}
                            </b>
                            {games}
                          </>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {!showThx && (
            <div
              className="form"
              style={allDone && motivation.length > 5 ? {} : { opacity: 0.5 }}
            >
              <div className="field">
                <span className="label">Namn:</span>
                <input
                  type="text"
                  value={name}
                  name="name"
                  autoComplete="name"
                  disabled={!allDone && motivation.length < 5}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="field">
                <span className="label">Mejladress:</span>
                <input
                  type="text"
                  name="email"
                  autoComplete="email"
                  disabled={!allDone && motivation.length < 5}
                  value={email}
                  style={
                    email.length > 4 && !validateEmail(email)
                      ? { color: "red" }
                      : {}
                  }
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="field">
                <span className="label">Telefonnummer:</span>
                <input
                  disabled={!allDone && motivation.length < 5}
                  type="text"
                  name="tel"
                  autoComplete="phone"
                  value={phone}
                  onChange={(e) => {
                    var phoneRe = /^[29]\d{2}[29]\d{2}\d{4}$/;
                    var digits = e.target.value.replace(/\D/g, "");

                    setPhone(digits);
                  }}
                />
              </div>
              <div className="field fieldtex">
                <p>
                  Jag godkänner{" "}
                  <a
                    className="yellow"
                    href="./files/regler_och_villkor_hockeykampen.pdf"
                    target="_blank"
                  >
                    regler och villkor
                  </a>
                  <label className="cholder">
                    <input
                      disabled={!allDone && motivation.length < 5}
                      type="checkbox"
                      onClick={(e) => {
                        setCheck(e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </p>
                <div
                  className="submit"
                  onClick={() => {
                    if (
                      allDone &&
                      motivation.length > 5 &&
                      name.length > 0 &&
                      phone.length > 5 &&
                      check &&
                      email.length > 3 &&
                      !loading &&
                      validateEmail(email)
                    ) {
                      sendData();
                    }
                  }}
                  style={
                    allDone &&
                    motivation.length > 5 &&
                    name.length > 0 &&
                    phone.length > 5 &&
                    !loading &&
                    check &&
                    email.length > 3 &&
                    validateEmail(email)
                      ? { opacity: 1 }
                      : {}
                  }
                >
                  SKICKA
                </div>
              </div>
            </div>
          )}
          {showThx && (
            <div
              className="form"
              style={{
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h1 style={{ marginBottom: 0, fontSize: "2rem" }}>Tack!</h1>
              <br />
              <p style={{ margin: 0 }}>Du är nu med och deltar i tävlingen.</p>
            </div>
          )}
        </div>
      </div>
      <div
        className="block n1"
        style={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <a
          href="https://spela.svenskaspel.se/odds/sports/fotboll"
          target="_blank"
          style={
            isMobile
              ? {
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  background: "green",
                  width: "100%",
                  marginBottom: 10,
                }
              : { marginBottom: 10 }
          }
        >
          <img src={banner} style={{ width: "100%" }} />
        </a>
        <p>
          Spel från Svenska Spel Sport & Casino.
          <br />
          Åldersgräns 18år. {"  "}
          <a href="https://stodlinjen.se/" target="_blank">
            Stödlinjen.se
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
